@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

#maincontainer1{
  background-color: rgb(28, 39, 43);
  height: 100vh;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.head{
  color: white;
  position: relative;
    font-size: 2rem;
}
.head::after{
  content: "";
    position: absolute;
    width: 50%;
    top: 43px;
    right: 39px;
    height: 3px;
    background: white;
}
.head2{
  font-size: 3rem;
    color: #ff6c37;
    text-transform: uppercase;
    margin: 2rem;
    position: relative;
}
.head2::after{
  content: "";
  position: absolute;
  width: 50%;
  top: 59px;
  right: 143px;
  height: 3px;
  background: #ff6c37;
}
.signin{
  padding: 2rem;
  border: 1px solid #ff6c37;
  border-radius: 13px;
  background: #ff6c371a;
}
.signin h1{
  color: white;
  text-align: center;
}
footer {
  position: fixed;
  background-color: rgb(28, 39, 43);
  color: white;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px;
  text-align: center;
  font-size: 10px;
  border-top: 1px solid gray;
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.main{
    height: 100vh;
    width: 100%;
    overflow: hidden;
    background: rgb(28, 39, 43);
    position: relative;
}
.main .header{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: .7rem 1rem .7rem 2rem;
    /* border-bottom: 1px solid gray; */
    background-color: #222831;
}
.main .header .left h2{
    color: white;
}
.main .header h4{
    text-transform: uppercase;
    text-align: left;
    color: #00bea6;
    /* margin-right: -12rem; */
}
.main .header .right a{
    line-height: 1.5;
    border-radius: 3px;
    border: navajowhite;
    font-weight: 700;
    font-size: 14px;
    padding: 12px 16px;
    text-align: center;
    color: rgb(255, 255, 255);
    background-color: #00bea6 !important;
    border-color: #00bea6 !important;
    margin-left: 1rem;
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: none;
}
.main .header .right button{
    line-height: 1.5;
    border-radius: 3px;
    border: navajowhite;
    font-weight: 600;
    font-size: 14px;
    padding: 12px 16px;
    text-align: center;
    color: rgb(0, 0, 0);
    background-color: #00bea6 !important;
    border-color: #00bea6 !important;
    margin-left: 1rem;
    text-transform: uppercase;
    cursor: pointer;
}
.main .header .right .info{
  margin: 0;
  border-radius: 10px;
  padding: 2px 10px;
  text-transform: lowercase;
}
.main .header .right a:hover,.main .header .right button:hover{
    
    background-color: #028f7c !important;
    border-color: #028f7c !important;
}
.main .header .right a .MuiSvgIcon-root
{
    margin-bottom: -5px;
}
.maincontainer{
    display: -webkit-flex;
    display: flex;
    height: 90vh;
}
.maincontainer .leftmost{
    -webkit-flex: 0.20 1;
            flex: 0.20 1;
    padding: 1rem;
    display: block;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    color: white;
    border-right: 1px gray solid;
    text-align: center;
    overflow-y: scroll;
}
.maincontainer .leftmost ul li{
    color: #00bea6;
}
.maincontainer .middle{
    -webkit-flex: 0.60 1;
            flex: 0.60 1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.maincontainer .right{
    -webkit-flex: 0.20 1;
            flex: 0.20 1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    height: 100%;
    padding: 1rem;
    border-left: 1px solid gray;
    overflow-x: scroll;
}
.generate{
    line-height: 1.5;
    border-radius: 3px;
    border: navajowhite;
    font-weight: 700;
    font-size: 14px;
    padding: 12px 16px;
    text-align: center;
    color: rgb(0, 0, 0);
    background-color: #00bea6 !important;
    border-color: #00bea6 !important;
    text-transform: uppercase;
    cursor: pointer;
    margin-top: 1rem;
}

.input-box .details{
    font-weight: 500;
    margin-bottom: 5px;
    color: white;
}
.input-box input{
    height: 45px;
    width: 100%;
    outline: none;
    border-radius: 5px;
    margin: 6px 0 10px 0;
    border: 1px solid rgb(107, 107, 107);
    padding-left: 15px;
    font-size: 16px;
    border-bottom-width: 2px;
    transition: all 0.3s ease;
    font-weight: 500;
}
.input-box select{
  height: 45px;
  width: 100%;
  outline: none;
  border-radius: 5px;
  margin: 6px 0 10px 0;
  border: 1px solid rgb(107, 107, 107);
  padding-left: 15px;
  font-size: 16px;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
  font-weight: 500;
}
.input-box textarea{
    height: 100px;
    width: 100%;
    outline: none;
    border-radius: 5px;
    margin: 6px 0 10px 0;
    border: 1px solid rgb(107, 107, 107);
    padding-left: 15px;
    padding-top: 5px;
    font-size: 16px;
    border-bottom-width: 2px;
    transition: all 0.3s ease;
    font-weight: 500;
    font-family: inherit;
}
.right .form{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }


  @media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: auto !important;
    height: initial !important;
    overflow: visible !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 20mm;
}
.popup{
  position: fixed;
  /* top: 50%; */
  /* left: 50%; */
  z-index: 100;
  background: rgba;
  background: rgba(0,0,0,0.5);
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  transition: 1s ease;
}
.popup-box{
  background: white;
  padding: 2rem;
  border-radius: 5px;
  position: relative;
}
.popup-box button{
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 6px 10px;
  border: none;
  background: #00bea6;
  color: white;
  border-radius: 50%;
  font-weight: bold;
  cursor: pointer;
}
.popup-box .count{
  text-align: center;
  margin-top: 10px;
  background-color: #00bea6;
  padding: 10px;
  border-radius: 10px;
}
.popup-box li{
  color: #00bea6;
  font-weight: 400;
  font-size: 14px;
}
.leftmost .templates{
  padding: 2px;
    overflow: hidden;
    border: 1px solid #00bea6;
    margin: 1rem;
    border-radius: 0px;
    background: #ff57222b;
    cursor: pointer;
    transition: 0.5s;
}
.leftmost .active{
  box-shadow: 0 0 5px 2px #00bea6;
  -webkit-transform: scale(1.05);
          transform: scale(1.05)
}
.leftmost .templates img{
  width: 100%;
  height: 100%;
}
#toggler {
  margin-left: 1rem;
  padding: 5px;
  background: #00bea6;
  border: none;
  color: white;
  line-height: 1.5;
  border-radius: 3px;
  border: navajowhite;
  font-weight: 700;
  font-size: 11px;
  /* padding: 12px 16px; */
  text-align: center;
  color: rgb(255, 255, 255);
  background-color: #00bea6 !important;
  border-color: #00bea6 !important;
  margin-left: 1rem;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
}
.header .left {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

button:disabled,
button[disabled]{
  background-color: #afafaf !important;
  color: #666666;
  cursor: not-allowed;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-bottom: 10px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

.switch input:checked + .slider {
  background-color: #2196F3;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.switch .slider.round {
  border-radius: 34px;
}

.switch .slider.round:before {
  border-radius: 50%;
}
